import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

function LinkAVE2023() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="my-16 mx-8 justify-center text-center md:text-left">
      <div className="flex flex-col mb-8 gap-4">
        <div>
          <div
            data-aos="fade-up"
            className="uppercase font-bold text-gray-600 tracking-widest"
          >
            Link Access
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="text-5xl md:text-6xl font-bold"
          >
            <span className="text-[#B16784]">A</span>irlangga{" "}
            <span className="text-[#B16784]">V</span>irtual{" "}
            <span className="text-[#B16784]">E</span>xhibition
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="bg-gray-200 rounded-full text-gray-600 md:w-fit px-4 py-1 font-medium text-sm"
        >
          Pameran Karya Infografis
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        className="grid grid-cols-1 md:grid-cols-3 py-8 gap-4"
      >
        <Link
          to="https://framevr.io/asicsiswa1"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Siswa #1
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicsiswa1
          </p>
        </Link>
        <Link
          to="https://framevr.io/asicsiswa2"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Siswa #2
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicsiswa2
          </p>
        </Link>
        <Link
          to="https://framevr.io/asicsiswa3"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Siswa #3
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicsiswa3
          </p>
        </Link>
        <Link
          to="https://framevr.io/asicmahasiswa1"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Mahasiswa #1
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicmahasiswa1
          </p>
        </Link>
        <Link
          to="https://framevr.io/asicmahasiswa2"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Mahasiswa #2
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicmahasiswa2
          </p>
        </Link>
        <Link
          to="https://framevr.io/asicmahasiswa3"
          target="_blank"
          className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
        >
          <h2 className="text-xl font-semibold cursor-pointer uppercase">
            ASIC Mahasiswa #3
          </h2>
          <p className="text-gray-500 cursor-pointer">
            https://framevr.io/asicmahasiswa3
          </p>
        </Link>
      </div>
    </div>
  );
}

export default LinkAVE2023;
